<template>
	<transition name="slide">
		<div class="formBlock setMenuColor oldForm" data-menu-color="light" id="form" v-if="success" key="success">
			<div class="container">
				<div class="formBlock__left">
					<p>Обсудить проект</p>
				</div>
				<div class="formBlock__right">
					<div class="formBlockSuccess">
						<div class="formBlockSuccess__left">
							<div class="formBlockSuccess__header">
								<p class="formBlockSuccess--title">Спасибо за обращение!</p>
								<p class="formBlockSuccess--desc"> Мы скоро свяжемся с Вами</p>
							</div>
							<a class="openForm" @click="openForm">Отправить еще раз</a>
						</div>
						<div class="formBlockSuccess__right">
							<img :src="require(`@/assets/img/success.png`)" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="formBlock setMenuColor oldForm" data-menu-color="light" id="form" v-else key="form">
			<div class="container">
				<div class="formBlock__left">
					<p>Отклик на вакансию</p>
				</div>
				<div class="formBlock__right">
					<div class="formBlock__header">
						<p class="formBlock--title">Заполните форму, мы перезвоним и обсудим</p>
					</div>
					<form class="form" name="vacancy" autocomplete="off">
						<input type="hidden" v-model.lazy="formValues.testBot">
						<div class="form__item--throuble">
							<div class="form__item">
								<p class="form__item--title">Ваше имя</p>
								<input type="text" class="required" placeholder="Константин" data-mask="singlename" v-model.lazy="formValues.name" autocomplete="nope">
								<span class="validation-error"></span>
							</div>
							<div class="form__item">
								<p class="form__item--title">Телефон</p>
								<input type="tel" inputmode="numeric" class="required" placeholder="+7 999 999 99 99" data-mask="phone" v-model.lazy="formValues.phone" autocomplete="nope">
								<span class="validation-error"></span>
							</div>
							<div class="form__item">
								<p class="form__item--title">E-mail</p>
								<input type="text" class="required" placeholder="mail@mail.ru" data-mask="email" v-model.lazy="formValues.email" autocomplete="nope">
								<span class="validation-error"></span>
							</div>
						</div>
						<div class="form__item--oneToTwo mb24">
							<div class="form__item select">
								<p class="form__item--title">Желаемая должность</p>
								<input type="text" class="required" name="vacancy" v-model.lazy="formValues.vacancy" autocomplete="nope">
								<div class="select__header" data-defaultText="Выберите вакансию">
									Выберите вакансию
								</div>
								<div class="select__body">
									<VuePerfectScrollbar class="select__items" :settings="scrollSettings">
										<div class="select__item" v-for="(vacancy, index) in vacancies" :data-value="vacancy.title" :data-text="vacancy.title" :key="index">
											<p class="select__item--title">{{vacancy.title}}</p>
											<p class="select__item--desc">{{vacancy.salary}}</p>
										</div>
										<div class="select__item" data-value="Другая" data-text="Другая">
											<p class="select__item--title">Другая</p>
										</div>
									</VuePerfectScrollbar>
								</div>
								<span class="validation-error"></span>
							</div>
							<div class="form__item">
								<p class="form__item--title">Прикрепить резюме</p>
								<div class="fileInput">
									<p class="fileInput--text hide600">Добавьте документ или <span>выберите вручную</span></p>
									<p class="fileInput--text show600">Добавьте документ</p>
									<input type="file" name="file" data-mask="file" autocomplete="off" data-onerequired="feedback1">
								</div>
								<span class="validation-error"></span>
							</div>
						</div>
						<div class="form__item--oneToTwo">
							<div class="form__item">
								<p class="form__item--title">Возраст</p>
								<input type="text" class="required" placeholder="Возраст" data-mask="number" v-model.lazy="formValues.years" autocomplete="nope">
								<span class="validation-error"></span>
							</div>
							<div class="form__item">
								<p class="form__item--title">Сопроводительное письмо</p>
								<textarea placeholder="Текст о себе" v-model.lazy="formValues.text" class="big"></textarea>
							</div>
						</div>
						<div class="form__button buttonWithText">
							<a class="button button--red submit" @click="submit">Отправить заявку</a>
							<p class="fs13">
								Отправляя заявку, вы соглашаетесь с<br> политикой конфиденциальности
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	</transition>
</template>



<style lang="sass">
	@import '@/assets/sass/forms/mainForm'
</style>

<script>
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'

	export default {
		props: ['vacancies'],
		data: () => ({
			success: false,
			formValues: {
				formName: 'vacancy',
				name: '',
				phone: '',
				email: '',
				vacancy: '',
				years: '',
				text: '',
				fileName: '',
				page: '',
				testBot: ''
			},
			scrollSettings: {
				wheelPropagation: false
			}
		}),
		mounted(){
			this.$el.querySelectorAll('input').forEach(input => {
				initValidator(input);
			});
		},
		methods: {
			submit: function(e){
				var comp = this;
				e.preventDefault();
				var form = e.target.closest("form");
				var inputes = form.getElementsByTagName("input");
				inputes.forEach(function(input) {
					if((input.offsetWidth > 0 && input.offsetHeight > 0) || (input.closest('.select') && input.closest('.select').offsetWidth > 0 && input.closest('.select').offsetHeight > 0)){
						validate(input);
					}
				});

				if(form.elements["file"].value){
					this.formValues.fileName = form.elements["file"].files[0].name;
				}
				
				this.formValues.page = this.$router.app._route.path;

				if(!form.getElementsByClassName("error").length){
					var data = new FormData();
					data.append("json", JSON.stringify(this.formValues));

					const request = new XMLHttpRequest();
					const url = "/local/include/ajax/feedback.php";

					request.responseType = "json";
					request.open("POST", url);
					
					request.addEventListener("readystatechange", () => {
 
						if (request.readyState === 4 && request.status === 200) {
							let obj = request.response; 
							comp.success = true;
							setTimeout(function(){
								doScrolling(e.target.closest(".formBlock").offsetTop, 400);
							}, 400);
						}
					});
					 
					request.send(data);
				}
			},
			openForm: function(e){
				var comp = this;
				var form = e.target.closest(".formBlock");
				var inputes = form.getElementsByTagName("input");
				setTimeout(function(){
					inputes.forEach(function(input) {
						if(input.attributes.type.value == "text"){
							input.value = "";
							input.closest('.form__item').classList.remove("error");
						} else if(input.attributes.type.value == "file"){
							if(input.closest(".fileInput").getElementsByClassName("fileInputFile--delete").length){
								input.closest(".fileInput").getElementsByClassName("fileInputFile--delete")[0].click();
							}
						}
					});
				});

				this.formValues.name = '';
				this.formValues.phone = '';
				this.formValues.email = '';
				this.formValues.years = '';
				this.formValues.page = '';
				this.formValues.fileName = '';

				this.success = false;
				setTimeout(function(){
					comp.$el.querySelectorAll('input').forEach(input => {
						initValidator(input);
						doScrolling(e.target.closest(".formBlock").offsetTop, 400);
					});
				}, 400);
			}
		},
		components: {
			VuePerfectScrollbar
		}
	}
</script>