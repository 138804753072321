<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>
		<VacanciesForm :vacancies="vacancies"></VacanciesForm>
	</div>
</template>


<script>
	import WithLeftMenu from '@/components/app/WithLeftMenu'
	import VacanciesForm from '@/components/app/forms/Vacancies'

	// import vacanciesData from "@/assets/json/vacancies.json";

	export default {
		metaInfo: {
			title: 'Вакансии веб-студии | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Вакансии компании Артрокетс. Добро пожаловать в команду «строителей», создающих компанию мечты.' },
				{ vmid: 'og:title', property: 'og:title', content: 'Вакансии веб-студии | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Вакансии компании Артрокетс. Добро пожаловать в команду «строителей», создающих компанию мечты.' },
			],
		},
		data: () => ({
			vacancies: [],
			vacanciesData: [],
			withLeftMenuContent: [
				{
					component: 'contentBlock',
					name: 'Вакансии',
					data: [
						{
							component: 'vacancies',
							data: []
						}
					]
				}
			]
		}),
		methods: {
			getJSON: function(url, callback) {
				var xhr = new XMLHttpRequest();
				xhr.open('GET', url, true);
				xhr.responseType = 'json';
				xhr.onload = function() {
				  var status = xhr.status;
				  if (status === 200) {
					callback(null, xhr.response);
				  } else {
					callback(status, xhr.response);
				  }
				};
				xhr.send();
			}
		},
		mounted(){
			var comp = this;

			this.getJSON('/local/include/vacancies.json', function(err, data) {
				comp.vacanciesData = data;
				comp.withLeftMenuContent[0]["data"][0]["data"] = data;

				comp.vacanciesData.forEach(function(item) {
					comp.vacancies.push({
						'title': item.title,
						'salary': item.salary
					});
				});
			});

		},
		name: 'portfolio',
		components: {
			WithLeftMenu,
			VacanciesForm
		}
	}
</script>
